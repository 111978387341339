import * as React from "react"
import { Link } from "gatsby"

// markup
const NotFoundPage = () => {
  return (
    <main>
      no page found
    </main>
  )
}

export default NotFoundPage
